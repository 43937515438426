<template>
  <div>
    <loading-page
      :show-loading-page="showLoadingPage"
      :loading-message="loadingMessage"
    />
  </div>
</template>
<script>
import axios from 'src/app-axios';
import LoadingPage from '../dashboard/LoadingPage.vue';

export default {
    name: 'Logout',
    components: {
      'loading-page': LoadingPage,
    },

    data () {
      return {
        showLoadingPage: false,
        loadingMessage: '',
      };
    },
    mounted () {
      this.showLoadingPage = true;
      this.loadingMessage = 'Logging Off...';
      setTimeout(() => {
       this.logOut();
      }, 2000);
    },
    methods: {
      async logOut () {
        try {
          await axios.post('api/logout');
          this.$store.dispatch('logout');
        } finally {
          this.$store.dispatch('logout');
        }
      },
    },
};
</script>
